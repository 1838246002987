import { Component } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from "react-spinners"

import { SummaryItem } from './SummaryItem'
import Card, { SLRBaseProps } from './Card'
import Text from '../common/styled/Text'
import { TotalDivider } from './TotalDivider'
import { Box } from '@mui/material'
import { calculateNeedsPortfolioInTime, needsPortfolioModelUpdate, needsImmunizationPeriodUpdate, wantsPortfolioModelUpdate, wishesPortfolioModelUpdate } from '../../actions/standardLivingRisk'
import { loadModelOptions, loadConfigurationOptions } from '../../actions/portfolioDesigner'
import Chart from '../common/Chart'
import { LooseObject, SelectOption } from '../../common/types'
import { FundingType, NeedsImmunizationPeriod, NeedsPortfolioMethod } from '../../actions/types/standardLivingRisk'
import { Select } from '../common/styled/Select'
import { ReduxState } from '../../reducers'
import { ModelItemOption } from '../../actions/types/portfolioDesigner'
import { addError } from '../../actions/notifications'
import { Link } from 'react-router-dom'
import MessageModal from '../common/MessageModal'
import { BucketingExampleImage } from '../../assets'
import Button from '../common/Button'
import { WithClientParameterProps, withClientParameter } from '../common/withClientParameter'
import CommonTable from '../common/CommonTable'
import { formatNumberAsPercentage } from 'src/common/utils'

const FIXED_MONEY_GUIDE_INV_PORT_TODAY = 5000000
interface SLRProps extends SLRBaseProps, WithClientParameterProps {
    investmentPortfolioToday: number
    slr: string
    hideHeader: boolean
    hideFooter: boolean
    needsInTimeChart: LooseObject
    wantsInTimeChart: LooseObject
    wishesInTimeChart: LooseObject
    calculatingNeeds: boolean
    needsPortfolio: number
    wantsPortfolio: number
    wishesPortfolio: number
    yearsInFinancialGoal: number;
    yearsToFinancialGoal: number;
    needsWentNegative: boolean
    needsImmunizationPeriod: number;
    needsPortfolioModel: ModelItemOption | null
    wantsPortfolioModel: ModelItemOption | null
    wishesPortfolioModel: ModelItemOption | null
    portfolioModels: SelectOption[]
    financialPlanNeedsModels: SelectOption[]
    financialPlanSource: string
    montecarloSuccessRate?: {
        needs: number;
        wants: number;
        wishes: number;
    }
    needsImmunizationPeriodUpdate: (option: SelectOption) => void
    needsPortfolioModelUpdate: (option: ModelItemOption) => void
    wantsPortfolioModelUpdate: (option: ModelItemOption) => void
    wishesPortfolioModelUpdate: (option: ModelItemOption) => void
    loadModelOptions: typeof loadModelOptions,
    loadConfigurationOptions: typeof loadConfigurationOptions,
    calculateNeedsPortfolioInTime: () => void
    addError: typeof addError
}

interface SLRState {
    selectedChartOption: SelectOption | null
    showBucketingExample: boolean
    needsImmunizationPeriod: SelectOption | null
    needsImmunizationPeriodOptions: SelectOption[]
}

export const TREASURY_LADDER_OPTION = { label: 'Treasury Ladder', value: NeedsPortfolioMethod.TREASURY_LADDER };
export const CHART_OPTIONS = [{ label: 'Needs Portfolio', value: FundingType.NEED },{ label: 'Wants Portfolio', value: FundingType.WANT }, { label: 'Wishes Portfolio', value: FundingType.WISH }]
export const NEEDS_IMMUNIZATION_PEROD = [{ label: '5 Year', value: NeedsImmunizationPeriod.FIVE_YEAR }, { label: '10 Year', value: NeedsImmunizationPeriod.TEN_YEAR }]

class SLR extends Component <SLRProps, SLRState> {

    constructor(props: SLRProps) {
        super(props)
        this.props.calculateNeedsPortfolioInTime()
        const needsImmunizationPeriodOptions = this.getNeedsImmunizationPeriodOptions(props.yearsToFinancialGoal, props.yearsInFinancialGoal);
        const needsImmunizationPeriod = needsImmunizationPeriodOptions.find(({ value }) => value === props.needsImmunizationPeriod) || null
        this.state = {
            selectedChartOption: CHART_OPTIONS[0],
            showBucketingExample: false,
            needsImmunizationPeriodOptions,
            needsImmunizationPeriod,
            
        }
    }
    
    componentDidMount(): void {
        this.props.loadModelOptions();
        this.props.loadConfigurationOptions();
    }

    componentDidUpdate(prevProps: SLRProps) {
        if (prevProps.yearsToFinancialGoal !== this.props.yearsToFinancialGoal || prevProps.yearsInFinancialGoal !== this.props.yearsInFinancialGoal) {
            const needsImmunizationPeriodOptions = this.getNeedsImmunizationPeriodOptions(this.props.yearsToFinancialGoal, this.props.yearsInFinancialGoal);
            const needsImmunizationPeriod = needsImmunizationPeriodOptions.find(({ value }) => value === this.props.needsImmunizationPeriod) || null
            this.setState({ needsImmunizationPeriodOptions, needsImmunizationPeriod })
        }
        if (prevProps.needsImmunizationPeriod !== this.props.needsImmunizationPeriod) {
            const needsImmunizationPeriodOptions = this.getNeedsImmunizationPeriodOptions(this.props.yearsToFinancialGoal, this.props.yearsInFinancialGoal);
            const needsImmunizationPeriod = needsImmunizationPeriodOptions.find(({ value }) => value === this.props.needsImmunizationPeriod) || null
            this.setState({ needsImmunizationPeriod })
        }
    }


    getNeedsImmunizationPeriodOptions = (yearsToFinancialGoal: number, yearsInFinancialGoal: number) => {
        const totalDuration = yearsToFinancialGoal + yearsInFinancialGoal
        const bucketCount = Math.ceil(totalDuration / 5)
        
        const options = []


        for (let i = 1; i <= bucketCount; i++) {
            options.push({ label: `${i * 5} Year`, value: i * 5 })
        }

        return options;
    }

    setSelectedChartOption = (selectedChartOption: SelectOption) => {
        this.setState({ selectedChartOption })
    }
    setSelectedNeedsImmunizationPeriod = (selectedOption: SelectOption) => {
        this.props.needsImmunizationPeriodUpdate(selectedOption)
    }


    setSelectedNeedsPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })

            return
        }
        this.props.needsPortfolioModelUpdate(selectedOption)
    }

    setSelectedWantsPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })
            
            return
        }
        this.props.wantsPortfolioModelUpdate(selectedOption)
    }

    setSelectedWishesPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })
            
            return
        }
        this.props.wishesPortfolioModelUpdate(selectedOption)
    }

    onCloseBucketingExample = () => {
        this.setState({ showBucketingExample: false })
    }

    onShowBucketingExample = () => {
        this.setState({ showBucketingExample: true })
    }

    render() {
        const { investmentPortfolioToday, needsInTimeChart, wantsInTimeChart, wishesInTimeChart,
                needsWentNegative, needsPortfolio, wantsPortfolio, wishesPortfolio, onComponentSize,
                needsPortfolioModel, calculatingNeeds, wantsPortfolioModel, wishesPortfolioModel,
                portfolioModels, financialPlanNeedsModels, financialPlanSource, clientId, montecarloSuccessRate } = this.props
        const { needs, wants, wishes } = montecarloSuccessRate || { }
        const needsSuccessRate = needs !== undefined ? formatNumberAsPercentage(needs) : '--';
        const wantsSuccessRate = wants !== undefined ? formatNumberAsPercentage(wants) : '--';
        const wishesSuccessRate = wishes !== undefined ? formatNumberAsPercentage(wishes) : '--';

        const { selectedChartOption } = this.state

        const needsPortfolioDisplay = needsWentNegative ? '-' : needsPortfolio
        const wantsPortfolioDisplay = needsWentNegative ? '-' : wantsPortfolio
        const wishesPortfolioDisplay = needsWentNegative ? '-' : wishesPortfolio
        const showRawValues = needsWentNegative;
        let inTimeChart = undefined
        if (selectedChartOption?.value === FundingType.NEED) {
            inTimeChart = needsInTimeChart
        }
        if (selectedChartOption?.value === FundingType.WANT) {
            inTimeChart = wantsInTimeChart
        }
        if (selectedChartOption?.value === FundingType.WISH) {
            inTimeChart = wishesInTimeChart
        }

        const isMoneyGuide = financialPlanSource === 'money_guide'

        return (
            <Box sx={{ width: '72.5rem', display: 'flex', flexDirection: 'row' }}>
                <Card sx={{ width: '72.5rem'}} title={'ALM Risk'} hideHeader hideFooter={this.props.hideFooter} onComponentSize={onComponentSize} showAddProfileHistory={false}>
                    <MessageModal hideHeader title={''} visible={this.state.showBucketingExample} onCancel={this.onCloseBucketingExample} onButtonPress={this.onCloseBucketingExample} >
                        <BucketingExampleImage />
                    </MessageModal>
                    <Box sx={{ minHeight: '38rem' }}>
                        <SummaryItem label={'Investment Portfolio'} value={investmentPortfolioToday} />
                        <TotalDivider />
                        <SummaryItem
                            label={
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Text type={'form_header'} sx={{ width: '16rem' }}>Needs Portfolio</Text>
                                    <Select sx={{ width: '25rem' }} options={financialPlanNeedsModels} value={needsPortfolioModel} onChange={this.setSelectedNeedsPortfolioModel} />
                                    <Select sx={{ width: '15rem', marginLeft: '1rem' }} options={this.state.needsImmunizationPeriodOptions} value={this.state.needsImmunizationPeriod} onChange={this.setSelectedNeedsImmunizationPeriod} />
                                </Box>
                            }
                            displayRawValue={showRawValues}
                            value={needsPortfolioDisplay}
                        />
                        <SummaryItem
                            label={
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Text type={'form_header'} sx={{ width: '16rem' }}>Wants Portfolio</Text>
                                    <Select sx={{ width: '25rem' }} options={portfolioModels} value={wantsPortfolioModel} onChange={this.setSelectedWantsPortfolioModel} />
                                </Box>
                            }
                            displayRawValue={showRawValues}
                            value={wantsPortfolioDisplay}
                        />
                        <SummaryItem
                            label={
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Text type={'form_header'} sx={{ width: '16rem' }}>Wishes Portfolio</Text>
                                    <Select sx={{ width: '25rem' }} options={portfolioModels} value={wishesPortfolioModel} onChange={this.setSelectedWishesPortfolioModel} />
                                </Box>
                            }
                            displayRawValue={showRawValues}
                            value={wishesPortfolioDisplay}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Link onClick={this.onShowBucketingExample} to={'#'}>
                                <Text
                                    sx={{ 
                                        display: 'flex',
                                        textDecoration: 'underline',
                                        textAlign: 'center',
                                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                        fontFamily: 'Bowlby One SC',
                                        color: 'navyblue',
                                        
                                    }}>
                                    How Does Bucketing Work?<br /> A Simple Example
                                </Text>
                            </Link>
                            {isMoneyGuide && <Box sx={{ marginTop: '1rem'}}><Text>Source: MoneyGuide</Text></Box> }
                            {!isMoneyGuide &&<Button title={<>Update Savings<br />& Goals</>} href={`/software/clients/${clientId}/plan`} sx={{ minWidth: 'unset', minHeight: 'unset', width: '20rem', height: '4.5rem' }} /> }
                    </Box>
                   
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {needsWentNegative && 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                            <Text sx={{ color: '#b20000', fontWeight: 'bold' }}>Warning: Needs Can't Be Met. Please Update Your Assumptions.</Text>
                        </Box>
                    }
                    </Box>
                </Card>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10rem',  }}>
                        <Select sx={{ width: '45rem', marginLeft: '1rem' }} options={CHART_OPTIONS} value={selectedChartOption} onChange={this.setSelectedChartOption} />
                        
                        {(calculatingNeeds)&&
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5rem' }}>
                                <ClipLoader />
                            </Box>
                        }
                        {inTimeChart && !calculatingNeeds &&
                            <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                <Chart id={'needsInTimeChart'} sx={{ width: '45rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={inTimeChart} />
                            </Box>
                        }
                        {!inTimeChart && !calculatingNeeds &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Text sx={{ fontWeight: 'bold' }}>No Data Available</Text>
                            </Box>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '5rem', justifyContent: 'center' }}>
                        <CommonTable
                            sx={{ width: '35rem', marginTop: '1rem' }}
                            headerColumns={[{ key: '', children: '', sx: { minWidth: '15rem' } }, { key: 'Success Rate', children: 'Success Rate' }]}
                            rows={[
                                { title: 'Needs Portfolio', values: [<Box sx={{ display: 'flex', justifyContent: 'center' }} >{calculatingNeeds ? <ClipLoader size={20} /> : needsSuccessRate}</Box>]},
                                { title: 'Wants Portfolio', values: [<Box sx={{ display: 'flex', justifyContent: 'center' }}>{calculatingNeeds ? <ClipLoader size={20} /> : wantsSuccessRate}</Box>]},
                                { title: 'Wishes Portfolio', values: [<Box sx={{ display: 'flex', justifyContent: 'center' }}>{calculatingNeeds ? <ClipLoader size={20} /> : wishesSuccessRate}</Box>]},
                            ]}
                        />
                    </Box>
            </Box>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk, portfolioDesigner }: ReduxState) => {
    const { investment_portfolio_today: investmentPortfolioToday, standardLivingRisk: slr, calculatingNeeds, 
            needsPortfolio, wantsPortfolio, wishesPortfolio, needsWentNegative, needsInTimeChart, wantsInTimeChart, wishesInTimeChart,
            financial_plan_source, needs_immunization_period, needs_model_id, wants_model_id, wishes_model_id,
            years_in_financial_goal: yearsInFinancialGoal, years_to_financial_goal: yearsToFinancialGoal, montecarloSuccessRate } = standardLivingRisk
    const { portfolioModels, financial_plan_needsModels: financialPlanNeedsModels } = portfolioDesigner;
            
    
    return {
        investmentPortfolioToday: financial_plan_source === 'money_guide' ? FIXED_MONEY_GUIDE_INV_PORT_TODAY : investmentPortfolioToday,
        financialPlanSource: financial_plan_source,
        slr,
        calculatingNeeds,
        needsPortfolio,
        wantsPortfolio,
        wishesPortfolio,
        needsInTimeChart,
        wantsInTimeChart,
        wishesInTimeChart,
        needsWentNegative,
        portfolioModels,
        financialPlanNeedsModels,
        yearsInFinancialGoal,
        yearsToFinancialGoal,
        montecarloSuccessRate,
        needsImmunizationPeriod: needs_immunization_period,
        needsPortfolioModel: financialPlanNeedsModels?.find(({ value }) => value === needs_model_id) || null,
        wantsPortfolioModel: portfolioModels.find(({ value }) => value === wants_model_id) || null,
        wishesPortfolioModel: portfolioModels.find(({ value }) => value === wishes_model_id) || null,

    }
}

export default withClientParameter(connect(mapStateToProps, { calculateNeedsPortfolioInTime, needsPortfolioModelUpdate, needsImmunizationPeriodUpdate, wantsPortfolioModelUpdate, wishesPortfolioModelUpdate, addError, loadModelOptions, loadConfigurationOptions })(SLR as any))